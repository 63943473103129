main {
    display: block;
}

.auth-form {
    width: 340px;
    margin: 0 auto;
}

.px-3 {
    padding-right: 16px !important;
    padding-left: 16px !important;
}

.session-authentication .auth-form-body {
    border-top: 1px solid #21262d;
    border-radius: 8px;
}

.auth-form-header {
    margin-top: 60px;
    margin-bottom: 15px;
    color: white;
    text-align: center;
    font-weight: 400;
    letter-spacing: .011em;
    font-family: SF Pro Display,SF Pro Icons,Helvetica Neue,Helvetica,Arial,sans-serif;
    text-shadow: none;
    border: 0;
}

.auth-form-body {
    padding: 16px;
    font-size: 14px;
}

.auth-form-body .form-textbox {
    margin-top: 4px;
    margin-bottom: 16px;
}

input {
    overflow: visible;
}

.position-relative {
    position: relative !important;
}

.session-authentication .auth-form label {
    display: block;
    margin-bottom: 8px;
    font-weight: 400;
    text-align: left;
}

.session-authentication .label-link {
    float: right;
    font-size: 12px;
}

.right-0 {
    right: 0 !important;
}

.top-0 {
    top: 0 !important;
}

.position-absolute {
    position: absolute !important;
}

.session-authentication .auth-form .btn {
    margin-top: 16px;
}

.btn-block {
    display: block;
    width: 100%;
    text-align: center;
}

.btn {
    position: relative;
    padding: 5px 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    transition: 80ms cubic-bezier(0.33, 1, 0.68, 1);
    transition-property: color, background-color, box-shadow, border-color;
    margin-top: 15px;
}

.login-callout {
    padding: 10px 16px;
    text-align: center;
    font-size: 14px;
    margin-bottom: 20px;
}

.login-forget-password {
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
    cursor: pointer;
}

.separator {
    background-color: #d2d2d7;
    background-size: cover;
    width: 100%;
    max-width: 268px;
    margin: auto;
    height: 0.2px;
    margin-top: 20px;
}
