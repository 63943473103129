#overview .link-overview,
#ide .link-ide,
#features .link-features,
#whats-new .link-whats-new,
#resources .link-resources {
    color: rgba(51, 51, 51, 0.6);
    pointer-events: none;
}

@media (prefers-color-scheme: dark) {
  html:not([data-color-scheme]) {
    background-color: black;
    color: white;
  }
}

@media (prefers-color-scheme: light) {
  html:not([data-color-scheme]) {
    background-color: white;
    color: black;
  }
}

html:not([data-color-scheme]) body {
  display: none;
}

@supports (-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px)) {

    /* Blur Navigation Bar */
    #ac-auroranav.ac-auroranav-scrim .ac-ln-background {
        background-color: rgba(255, 255, 255, 0.65);
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
    }
}

.button-pill {
    border-radius: 1em;
}

#main section.section {
    padding-top: 4em;
    padding-bottom: 4em;
}

#main section .section-content .row {
    margin-left: 0;
    margin-right: 0;
}

#main section .section-content .row>.column {
    padding: 0;
}

@media only screen and (max-width: 1068px) {
    #main section .section-content .row {
        margin-left: 0;
        margin-right: 0;
    }

    #main section .section-content .row>.column {
        padding: 0;
    }
}

@media only screen and (max-width: 735px) {
    #main section.section {
        padding-top: 2em;
        padding-bottom: 2em;
    }

    #main section .section-content .row {
        margin-left: 0;
        margin-right: 0;
        flex-direction: column;
        align-items: center;
    }

    #main section .section-content .row>.column {
        padding: 1em 0;
    }
}

.button-multi-container {
    width: fit-content;
}

.button-multi {
    position: relative;
    z-index: 3;
}

.button-multi::before {
    content: none;
}

.button-multi-content {
    color: #1d1d1f;
    border: 1px solid #d8d8d8;
    background-color: #f7f7f7;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    min-width: 28px;
    width: fit-content;
    margin-top: 7px;
    overflow: hidden;
    position: absolute;
    opacity: 0;
    transition: 0.25s ease-out;
    z-index: 2;
    pointer-events: none;
}

.button-multi-content-slide {
    transform: translateY(-15px);
}

.button-multi-option-active {
    opacity: 1;
    transform: translateY(0px);
    pointer-events: visible;
}

.button-multi-content-right {
    right: 0;
}

.row .button-multi-content-right {
    right: 1.5em;
}

@media only screen and (max-width: 734px) {
    .row .button-multi-content-right {
        right: 0;
    }
}

div.auroranav-action .button-multi-content-right {
    right: 20px;
}

@media only screen and (max-width: 767px) {
    div.auroranav-action .button-multi-content-right {
        right: 16px;
    }
}

.button-multi-option {
    color: #515154 !important;
    border-bottom: 1px solid rgb(216, 216, 216, 0.53);
    padding: 6px 16px;
}

.button-multi-option:last-child {
    border-bottom: none;
}

.button-multi-option:hover {
    background-color: #f0f0f0;
    text-decoration: none;
    color: #06c !important;
}

.button-multi-option:focus {
    outline: 4px solid rgba(0, 125, 250, 0.6);
    outline-offset: -5px;
}

.button-multi-option:active {
    background-color: #0071e3;
    color: #fff !important;
}

/* DARK MODE */
html[data-color-scheme="dark"] body .button-multi-content {
    color: #fff;
    border: 1px solid #545454;
    background-color: var(--fill-tertiary);
}

html[data-color-scheme="dark"] body .button-multi-option {
    color: var(--glyph-gray) !important;
    border-bottom: 1px solid #575757;
    opacity: 0.7;
}

html[data-color-scheme="dark"] body .button-multi-option:last-child {
    border-bottom: none;
}

html[data-color-scheme="dark"] body .button-multi-option:hover {
    background-color: var(--fill-secondary);
    color: var(--glyph-blue) !important;
    text-decoration: none;
    opacity: 1;
}

html[data-color-scheme="dark"] body .button-multi-option:active {
    background-color: #0071e3;
    color: #fff !important;
}

html[data-color-scheme="dark"] body#xcode .parallel-testing {
    filter: invert(1);
}

.gradient-text {
    background: linear-gradient(to right, #F36D6D, #F278F8, #9C84FA, #68DC7C, #F06EBE, #FAD66A, #F36D6D);
    background-size: 200% auto;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: shine 10s linear infinite;
            animation: shine 10s linear infinite;
}


@-webkit-keyframes shine {
    to {
      background-position: 200% center;
    }
}
@keyframes shine {
    to {
      background-position: 200% center;
    }
}

::selection {
    /* make the opacity of the content less */
    background: rgba(0, 0, 0, 0.3);
}

.shimmer {
  background: #edeef1;
  background-repeat: no-repeat;
  background-size: 800px 104px; 
  display: inline-block;
  position: relative; 
  color: transparent;
  -webkit-mask: linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
  mask: linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
  animation: shimmerEffect 1.5s infinite;
}

@keyframes shimmerEffect {
  100% {-webkit-mask-position:left;mask-position:left;}
}