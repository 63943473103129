.socials-router-links {
    background: #f5f5f7;
}

.socials-router-links .section-content,
.contributors-generator .section-content {
    padding-top: 24px;
    padding-bottom: 24px;
}

.socials-router-links a.block,
.contributors-generator .block {
    margin-left: auto;
    margin-right: auto;
}

.socials-router-links .fixed-width a.block,
.contributors-generator.fixed-width .block {
    width: 250px;
}

@media only screen and (max-width: 1068px) {
    .socials-router-links .fixed-width a.block,
    .contributors-generator.fixed-width .block {
        width: 216px;
    }
    .social-link-content {
        margin-left: auto;
        margin-right: auto;
        width: 251px;
    }
}

@media only screen and (max-width: 735px) {
    .socials-router-links .fixed-width a.block,
    .contributors-generator.fixed-width .block {
        width: 250px;
    }
}

@media only screen and (max-width: 480px) {
    .socials-router-links .small-6,
    .contributors-generator .small-6 {
        width: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
}

.router-icon {
    margin: 4px auto 10px;
    height: 50px;
    width: 50px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.alt-router-icon {
    margin: 4px 0px 10px;
    height: 50px;
    width: 50px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.router-icon-discord {
    background-image: url("../../elements/icons/socials/discord.svg");
    transition: all 0.2s ease-in-out;
}

.router-icon-discord:hover {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) infinite;
    transition: all 0.2s ease-in-out;
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}

.router-icon-slack {
    background-image: url("../../elements/icons/socials/slack.svg");
    transition: all 0.2s ease-in-out;
}

.router-icon-slack:hover {
    animation: infinite 1s linear 0s normal none running spin;
    transition: all 0.1s ease-in-out;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.router-icon-twitter {
    background-image: url("../../elements/icons/socials/twitter.png");
    transition: all 0.2s ease-in-out;
}

.router-icon-twitter:hover {
    animation: infinite 0.5s linear 0s normal none running tweet;
    transition: all 0.2s ease-in-out;
}

@keyframes tweet {
    0% {
        transform: rotate(0deg);
        transition: all 0.2s ease-in-out;
    }
    50% {
        transform: rotate(25deg);
        transition: all 0.2s ease-in-out;
    }
    100% {
        transform: rotate(-10deg);
        transition: all 0.2s ease-in-out;
    }
}

.router-icon-github {
    background-image: url("../../elements/icons/socials/github.svg");
    transition: all 0.2s ease-in-out;
}

.router-icon-github:hover {
    animation: infinite 0.5s linear 0s normal none running github;
    transition: all 0.2s ease-in-out;
}

@keyframes github {
    0% {
        transform: scale(1);
        transition: all 0.2s ease-in-out;
    }
    50% {
        transform: scale(1.1);
        transition: all 0.2s ease-in-out;
    }
    100% {
        transform: scale(1);
        transition: all 0.2s ease-in-out;
    }
}


.router-icon-reddit {
    background-image: url("../../elements/icons/socials/reddit.png");
    transition: all 0.2s ease-in-out;
}

.router-icon-reddit:hover {
    animation: infinite 0.5s linear 0s normal none running tweet;
    transition: all 0.2s ease-in-out;
}

@keyframes mastodon-shake-y {
    10%, 90% {
        transform: translate3d(0, -1px, 0);
    }

    20%, 80% {
        transform: translate3d(0, 2px, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(0, -4px, 0);
    }

    40%, 60% {
        transform: translate3d(0, 4px, 0);
    }
}

.router-icon-mastodon {
    background-image: url("../../elements/icons/socials/mastodon.png");
    transition: all 0.2s ease-in-out;
}

.router-icon-mastodon:hover {
    animation: mastodon-shake-y 0.82s cubic-bezier(.36,.07,.19,.97) infinite;
    transition: all 0.2s ease-in-out;
}

.theme-dark .socials-router-links {
    background-color: #1d1d1f;
}

.theme-dark .socials-router-links a.block span.block-link.more {
    color: #2997FF;
}

.theme-dark .router-icon-discord {
    background-image: url("../../elements/icons/socials/discord.svg");
}

.theme-dark .router-icon-slack {
    background-image: url("../../elements/icons/socials/slack.svg");
}

.theme-dark .router-icon-twitter {
    background-image: url("../../elements/icons/socials/twitter-dark.png");
    transition: all 0.2s ease-in-out;
}

.theme-dark .router-icon-github {
    background-image: url("../../elements/icons/socials/github.svg");
}

.contributor-image {
    border-radius: 50%;
    min-width: 99px;
    min-height: 99px;
}
